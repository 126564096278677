<template>
  <highcharts
    ref="planChart"
    v-loading="isLoading"
    class="chart"
    :options="chartOptions"
  ></highcharts>
</template>
<script>
import Sankey from "highcharts/modules/sankey.js";
import Organisation from "highcharts/modules/organization.js";
/*import Exporting from "highcharts/modules/exporting.js";
import Accessibility from "highcharts/modules/accessibility.js";*/
import Highcharts from "highcharts";
import _ from "lodash";
Sankey(Highcharts);
Organisation(Highcharts);
/*Exporting(Highcharts);
Accessibility(Highcharts);*/

export default {
  props: {
    report: {
      type: Object,
      required: false
    },

    title: {
      type: String,
      default: ""
    },

    fetchData: {
      type: Boolean,
      default: false
    },
    seriesData: {
      type: Array,
      default: () => [
        {
          data: []
        }
      ],
      required: false
    }
  },

  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          type: "organization",
          inverted: true,
          height: 800
        },
        credits: {
          enabled: false
        },
        title: {
          text: __("Plan Hierarchy")
        },
        series: [
          {
            data: []
          }
        ],
        tooltip: {
          enabled: false
        }
      },
      isLoading: false
    };
  },

  methods: {
    loadChartData() {
      this.chartOptions.series[0].data = this.seriesData;
    }
  },

  watch: {
    seriesData: {
      deep: true,
      immediate: true,
      handler: function(val) {
        _.forEach(val, function(item) {
          return _.reverse(item);
        });
        this.chartOptions.series[0].data = _.reverse(val);
        if (val.length * 100 < 400) {
          this.chartOptions.chart.height = 400;
          this.chartOptions.chart.width = 230;
        } else {
          this.chartOptions.chart.height = val.length * 100;
          this.chartOptions.chart.width = 230;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.chart {
  line-height: 0;
}
.highcharts-container {
  height: 800px !important;
}
</style>
